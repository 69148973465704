define('new-salsa-kl/routes/socials', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend({
    titleToken: "Socials",
    model: function model() {
      return this.get('store').findAll('social');
    }
  });
});