define('new-salsa-kl/routes/deejays/show', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend({

    // model(params) {
    //   return this.store.query('deejay', { filter: { slug: params.deejay_slug } }).then(deejays => {
    //     return deejays.get('firstObject');
    //   });
    // },

    // model(params) {
    //   if (!params.post_id) {
    //     params.post_id = params.post_slug.match(/[^-]*/)[0];
    //   }
    //   return this.store.peekRecord('deejay', params.deejay_id);
    // },

    // serialize: function(deejay, params) {
    //   return {
    //     deejay_slug:  deejay.get('slug')
    //   };
    // }

    // model(params) {
    //   return this.store.query('deejay', { filter: { slug: params.deejay_slug } })
    //     .then(deejays => {
    //       return deejays.get('firstObject');
    //     });
    // },

    // serialize(params, model) {
    //   return {
    //     model: model.get('slug')
    //   };
    // }
    model: function model(params) {
      return this.store.findRecord('deejay', params.deejay_id);
    }
  });
});