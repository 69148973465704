define('new-salsa-kl/routes/studios', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend({
    titleToken: "Salsa Studios",
    model: function model() {
      return this.get('store').findAll('studio').then(function (results) {
        return results.filter(function (studio) {
          return studio.get('status') === 'active';
        });
      });
    }
  });
});