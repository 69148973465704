define("new-salsa-kl/routes/application", ["exports", "ember"], function (exports, _ember) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend({
    title: function title(tokens) {
      return "Salsa KL - " + tokens;
    }
  });
});