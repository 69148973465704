define('new-salsa-kl/components/studio-list', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Component.extend({
    sortDefinition: ['name'],
    sortedStudios: _ember.default.computed.sort('studios', 'sortDefinition')
  });
});