define('new-salsa-kl/models/social', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    location: _emberData.default.attr(),
    day: _emberData.default.attr(),
    time: _emberData.default.attr(),
    dj: _emberData.default.attr(),
    imageUrl: _emberData.default.attr(),
    description: _emberData.default.attr(),
    website: _emberData.default.attr(),
    latitude: _emberData.default.attr(),
    longtitude: _emberData.default.attr(),
    slug: _emberData.default.attr()
  });
});