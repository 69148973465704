
if (typeof FastBoot === 'undefined') {
  if (!runningTests) {
    require('new-salsa-kl/app')['default'].create({"name":"new-salsa-kl","version":"0.0.0+502136b2"});
  }
}

define('~fastboot/app-factory', ['new-salsa-kl/app', 'new-salsa-kl/config/environment'], function(App, config) {
  App = App['default'];
  config = config['default'];

  return {
    'default': function() {
      return App.create(config.APP);
    }
  };
});

