define('new-salsa-kl/models/studio', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    location: _emberData.default.attr(),
    contactPerson: _emberData.default.attr(),
    contactNumber: _emberData.default.attr(),
    imageUrl: _emberData.default.attr(),
    website: _emberData.default.attr(),
    latitude: _emberData.default.attr(),
    longtitude: _emberData.default.attr(),
    status: _emberData.default.attr()
  });
});